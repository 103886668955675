.preorder-modal {
  &--heading {
    color: #db2f5c;
    font-size: 32px !important;
    text-align: center;
    padding: 20px 100px;
    @media screen and (max-width: 768px) {
      padding: 20px 40px !important;
    }
  }

  &--body {
    padding: 0 100px !important;
    @media screen and (max-width: 768px) {
      padding: 20px 40px !important;
    }

    h3 {
      font-weight: 600;
      font-size: 23px;
      margin-bottom: 20px;
    }

    p {
      span {
        font-weight: 600;
      }
    }

    .text-emphasize {
      color: #db2f5c;
      font-weight: 600;
      margin: 20px 0;
    }

    .mt-2 {
      margin-top: 20px;
    }
  }

  &--footer {
    padding: 20px 100px !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between !important;
    @media screen and (max-width: 768px) {
      padding: 20px 40px !important;
    }
  }
}
