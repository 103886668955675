.migration-page {
  min-height: calc(100vh - 280px);
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 991px) {
    margin-left: 0;
  }

  .migration-container {
    margin-top: 80px;

    h3 {
      font-size: 30px;
    }

    .accounts-table {
      th {
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 15px;
        text-decoration: none !important;
      }

      th,
      td {
        font-family: 'Montserrat', sans-serif;
        padding-left: 0;
      }
    }
  }
}
