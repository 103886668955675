.cart-item {
  width: 100%;
  display: flex;
  height: 80px;
  margin-bottom: 15px;

  img {
    width: 30%;
  }

  .item-details {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;

    &--wrapper {
      display: flex;
      flex-direction: column;
    }

    .name {
      font-size: 16px;
      color: #dc2f5c;
    }
  }

  .remove-button {
    cursor: pointer;
    transition: 0.3s all;
  }

  .remove-button:hover {
    color: #dc2f5c;
  }
}
