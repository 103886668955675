.footer {
  background-color: #2f2e2e;
  display: flex;

  .footer-credit {
    padding: 20px 0;
    display: flex;
    align-items: flex-start;

    h3 {
      font-weight: 600;
      margin-bottom: 10px;
      font-size: 25px;
    }

    h3,
    p,
    a {
      color: #fff;
      font-family: Montserrat, sans-serif;
    }

    a:hover {
      text-decoration: underline;
    }
    @media screen and (max-width: 578px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  @media screen and (max-width: 578px) {
    height: auto;
  }

  .social-media {
    display: flex;
    align-items: flex-end;
    @media screen and (max-width: 922px) {
      align-items: flex-start;
    }

    &--container {
      display: flex;
      width: 250px;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 20px 0;
      @media screen and (max-width: 578px) {
        margin-bottom: 10px;
        margin-top: 20px;
      }
    }

    p {
      font-size: 14px;
    }
  }

  .policy-pages-menu {
    margin-bottom: 10px;
    display: flex;

    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      list-style-type: none;
      @media screen and (max-width: 922px) {
        justify-content: flex-start;
      }

      a:last-child {
        margin-right: 0;
      }

      a {
        margin-right: 20px;
      }
    }
  }
}
