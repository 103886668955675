// .control-arrow {
//   width: 20px;
//   height: 20px;
//   position: relative;
//   top: 0 !important;
//   bottom: 0 !important;
//   margin: auto !important;
// }

// button:hover {
//   background-color: transparent !important;
// }

// .control-arrow::before {
//   position: absolute;
//   z-index: 10;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   margin: auto;
// }

// .control-prev::before {
//   width: 0;
//   height: 0;
//   border-top: 10px solid transparent !important;
//   border-bottom: 10px solid transparent !important;
//   border-right: 14px solid #000 !important;
// }

// .control-next::before {
//   width: 0;
//   height: 0;
//   border-top: 10px solid transparent !important;
//   border-bottom: 10px solid transparent !important;
//   border-left: 14px solid #000 !important;
// }

.carousel {
  padding-bottom: 20px;
}

.carousel .control-dots {
  .dot {
    background-color: #000;
    width: 10px;
    height: 10px;
  }
}
