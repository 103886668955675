.header {
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 40;
}

.top-header {
  flex: 1;
  height: 30px;
  padding: 5px 0;
  background-color: #2f2e2e;
  border-bottom: 1px solid rgb(173, 167, 167);
  z-index: 10;

  .current-user {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
  }

  p {
    color: #fff;
    margin: 0 10px;
  }

  .sign-out {
    color: #fff;
    cursor: pointer;
    transition: 0.3s all;
  }

  .sign-out:hover {
    color: #dc2f5c;
  }
}

.main-header {
  background-color: #fff;
  position: relative;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

  &--wrapper {
    height: 70px;
    width: 100%;
    padding: 20px 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    margin-right: auto;

    .logo-container {
      width: 140px;
      display: flex;
      align-items: center;
    }

    .options {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .option {
        padding: 5px 15px;
        cursor: pointer;
        position: relative;
        color: #000;
        font-weight: 500;
        transition: all 0.3s ease-in-out 0s;
        font-family: Montserrat, sans-serif;
      }

      .option:hover {
        // color: #d2a784;
        color: #dc2f5c;
      }

      .option::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 2px;
        width: 100%;
        background-color: #dc2f5c;
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
      }

      .option:hover::after {
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  .nav-icon {
    margin: 16px;
    width: 40px;
    cursor: pointer;
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  .hamburger-icon {
    display: flex;
    align-items: center;

    .cart-icon {
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }

  .nav-active {
    .nav-icon::before {
      transform: translateY(12px) rotate(135deg);
    }

    .nav-icon::after {
      transform: translateY(-12px) rotate(-135deg);
    }

    .nav-icon div {
      transform: scale(0);
    }
  }

  .nav-icon::after,
  .nav-icon::before,
  .nav-icon div {
    background-color: #000;
    border-radius: 3px;
    content: '';
    display: block;
    height: 5px;
    margin: 7px 0;
    transition: all 0.2s ease-in-out;
  }

  .mobile-header {
    min-height: 300px;
    width: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    background-color: #fff;
    top: -300px;
    left: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    &--options {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .option {
        cursor: pointer;
        padding: 10px 0 10px 16px;
        font-size: 25px;
        color: #000;
        font-family: Montserrat, sans-serif;
      }
    }
  }

  .mobile-header--active {
    top: 70px;
  }
}

.current-active-menu {
  color: #dc2f5c !important;
}

.chakra-menu__menu-button,
.chakra-menu__menu-button:hover,
.chakra-menu__menu-button:focus,
.chakra-menu__menu-button[data-active] {
  background-color: #fff !important;
  display: flex;
  justify-content: center;
}

.chakra-menu__menu-button[data-active] {
  svg {
    fill: #615f5f;
  }
}

.logged-state {
  svg {
    fill: #dc2f5c !important;
  }
}

.logged-state[data-active] {
  svg {
    fill: #dc2f5c80 !important;
  }
}

.chakra-menu__menu-button:hover {
  background-color: #fff;
}

.chakra-menu__menu-list {
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.25) !important;
  border-radius: 0 !important;
  padding: 0 20px;
  margin-top: 15px;

  .chakra-menu__menuitem {
    display: flex;
    justify-content: center;
    background-color: #dc2f5c !important;
    border-radius: 7px;
    color: #fff;
    font-family: Montserrat, sans-serif !important;
    font-weight: 500;
  }

  .chakra-menu__menuitem:hover {
    background-color: #dc2f5d80 !important;
  }
}

.login-menu {
  h2 {
    font-size: 24px;
    font-weight: 500;
  }

  h3 {
    margin: 10px 0;
  }

  .sign-out {
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 5px 0;
    border: 1px solid #dc2f5c;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    transition: 0.4s all;
  }

  .sign-out:hover {
    border: 1px solid #dc2f5c;
    background-color: #dc2f5c;
    color: #fff;
  }
}

.external-link {
  border: 1px solid #000;
  padding: 6px 25px;
  border-radius: 15px;
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  transition: 0.3s all;
  width: 150px;
  text-align: center;
  margin-left: 16px;
}

.external-link:hover {
  border: 1px solid #dc2f5c;
  background-color: #dc2f5c;
  color: #fff;
}

.user-btn:hover > span > svg {
  fill: #dc2f5c;
  transition: 0.3s all;
}
