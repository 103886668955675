.filter-container {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  h3 {
    font-weight: 500;
    font-size: 24px;
    margin: 0 20px;
    cursor: pointer;
    transition: 0.3s all;
  }

  h3:hover {
    color: #dc2f5c;
  }

  .active {
    position: relative;
    color: #dc2f5c;
  }

  .active::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: #dc2f5c;
    bottom: -5px;
    left: 0;
    right: 0;
    margin: auto;
  }
}
