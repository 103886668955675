.main-product {
  width: 100%;
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #d8d8d82c;
  padding: 120px 60px;
  @media screen and (max-width: 578px) {
    padding: 60px 30px;
  }

  .product-back-btn {
    h3 {
      font-size: 25px;
      font-weight: 500;
      cursor: pointer;
    }
  }

  .product-back-btn:hover {
    h3,
    svg {
      color: #5d5c5c !important;
      transition: 0.3s all;
    }
  }

  .product-details {
    flex: 1;

    hr {
      margin: 10px 0 20px;
      border-color: rgb(73 73 73);
    }

    h1 {
      font-size: 46px;
      font-weight: 600;
      @media screen and (max-width: 578px) {
        font-size: 34px;
        line-height: 40px;
        margin-bottom: 10px;
      }
    }

    .product-model {
      font-size: 24px;
      font-weight: 600;
      color: #dc2f5c;
    }

    .preorder-product {
      font-size: 16px;
      font-weight: 600;
      color: #0e8104;
    }

    .quantity-input {
      margin-top: 30px;

      h4 {
        font-size: 25px;

        span {
          margin: 0 10px;
          color: #dc2f5c;
        }
      }

      .chakra-button {
        background-color: #eee;
      }

      .chakra-button:hover {
        background-color: #ccc;
      }

      .chakra-input {
        width: 100px;
      }

      .chakra-input:focus {
        border-color: #dc2f5c;
      }
    }

    .checkout-button-group {
      display: flex;
      margin: 40px 0;

      .custom-button {
        margin-right: 10px;
      }
    }

    .product-tags-container {
      margin-top: 50px;
      display: flex;

      .product-tag {
        margin-right: 10px;
        background-color: #dc2f5c;
        padding: 20px;
        color: #fff;
        line-height: 0px;
        border-radius: 20px;
      }
    }

    .product-specs {
      color: #000;
    }

    .product-description {
      font-size: 18px;
    }

    .product-features {
      font-family: Montserrat, sans-serif;
      font-size: 18px;
      margin-top: 20px;

      h4 {
        font-size: 25px;
      }

      li {
        margin-left: 16px;
      }
    }

    .product-price {
      margin-top: 20px;

      span {
        font-size: 40px;
      }

      .prices-wrapper {
        display: flex;

        span {
          display: flex;
          align-items: center;
          font-size: 40px;
          color: #dc2f5c;
          text-transform: uppercase;
        }

        .sale-price .price-box h4 {
          font-size: 40px;
          color: #dc2f5c;
        }

        .regular-price .price-box h4 {
          font-size: 40px;
          color: #1a202c;
        }
      }

      .original-price {
        h4 {
          font-size: 40px;
          margin-left: 15px;
          color: rgb(156 156 156 / 40%) !important;
          text-decoration: line-through;
        }
      }
    }
  }
}

.add-ons {
  margin: 30px 0;

  h4 {
    font-size: 25px;
  }

  &--checkboxes {
    display: flex;
    flex-direction: column;

    /* Customize the label (the container) */
    .container {
      display: block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 10px;
      cursor: pointer;
      font-size: 22px;
      user-select: none;

      p {
        font-size: 18px;
      }

      .short-description {
        font-size: 14px;
        padding-left: 20px;
        position: relative;
      }

      .short-description::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        height: 1px;
        width: 15px;
        background-color: #dc2f5c;
      }
    }

    /* Hide the browser's default checkbox */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 2px;
      left: 0;
      height: 25px;
      width: 25px;
      background-color: #eee;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: #dc2f5c;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark::after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark::after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark::after {
      left: 8px;
      top: 3px;
      width: 9px;
      height: 14px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
}

.related-products {
  margin: 80px 0;

  h1 {
    text-align: center;
    font-size: 35px;
    margin: 0 30px 40px 30px;
  }

  &--container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

hr {
  opacity: 0.3;
  margin: 30px 0;
}
