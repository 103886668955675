.plans-page {
  width: 100%;
  padding: 100px 0 50px;
  background-color: #d8d8d82c;
  min-height: calc(100vh - 215px);
  font-family: Montserrat, sans-serif;

  .text-banner {
    background-color: #dc2f5c;
    overflow: hidden;
    position: relative;
    height: 50px;

    h1 {
      font-size: 35px;
      font-weight: 600;
      color: #fff;
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translateX(100%);
      animation: scroll-left 20s linear infinite;
    }
    @keyframes scroll-left {
      0% {
        transform: translateX(100%);
      }

      100% {
        transform: translateX(-100%);
      }
    }
  }
}
