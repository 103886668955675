.collection-item {
  width: 100%;
  height: 470px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: #f8f8f8;
  border-radius: 5px;
  overflow: hidden;

  .status-ribbon {
    position: absolute;
    right: -80px;
    top: 30px;
    width: 250px;
    transform: rotate(45deg);
    background-color: #000;
    z-index: 10;

    h2 {
      text-align: center;
      color: #fff;
      font-weight: 500;
      font-size: 15px;
    }
  }

  .image {
    width: 270px;
    height: 270px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 20px;
    margin-top: 20px;
    opacity: 0.85;
    transition: 0.3s all;
  }

  .custom-button {
    width: 80%;
    opacity: 0.7;
    position: absolute;
    top: 255px;
    display: none;
    transition: 0.3s all;
  }

  &:hover {
    .image {
      opacity: 1;
    }

    .custom-button {
      opacity: 0.85;
      display: flex;
    }
  }

  .collection-footer {
    width: 100%;
    height: 190px;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    font-weight: bold;
    padding: 0 15px;
    color: #000;
    background-color: #fff;

    .product-title {
      margin-top: 5px;

      .name {
        font-size: 22px;
        margin-top: 15px;
        font-weight: 600;
      }

      .model {
        color: #dc2f5c;
        font-size: 18px;
        margin-bottom: 5px;
        font-weight: 600;
      }
    }

    .short-description {
      font-size: 11px;
      font-family: Montserrat, sans-serif;
      font-weight: 400;
      margin-top: 20px;
      margin-bottom: 20px;
      width: 100%;
    }

    .product-price {
      display: flex;

      .prices-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .sale-price {
          h4 {
            color: #dc2f5c !important;
            font-size: 24px;
            font-weight: 600;
            letter-spacing: 0;
          }
        }
      }

      .original-price {
        color: #000;
        text-decoration: line-through;
        text-align: right;

        h4 {
          color: #000 !important;
          font-size: 15px;
          line-height: 20px;
          font-weight: 400;
          letter-spacing: 0;
        }
      }
    }

    .product-sku {
      p {
        font-size: 12px;
        font-weight: 300;
      }

      margin-bottom: 10px;
    }
  }

  .payment-tag {
    visibility: hidden;
    background-color: #dc2f5c;
    border-radius: 3px;
    padding: 3px 14px;
    white-space: nowrap;

    p {
      font-size: 12px;
      font-weight: 600;
      color: #fff;
    }
  }

  .payment-tag-visible {
    visibility: visible;
  }
}
