.cart-icon {
  width: 45px;
  height: 45px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s all;

  .shopping-bag-icon {
    width: 24px;
    height: 24px;
  }

  .item-count {
    position: absolute;
    font-size: 10px;
    font-weight: bold;
    bottom: 16px;
    color: #fff;
  }
}

.cart-icon::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #dc2f5c;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}

.cart-icon:hover::after {
  visibility: visible;
  transform: scaleX(1);
}

.disabled-cart-btn {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}
