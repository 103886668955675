.collection-preview {
  display: flex;
  flex-direction: column;
  margin: 30px;
  width: 370px;
  height: 470px;
  box-shadow: 0 7px 14px rgb(0 0 0 / 20%);
  transition: 0.3s all;

  .title {
    font-size: 28px;
    margin-bottom: 25px;
  }

  .preview {
    display: flex;
    justify-content: space-between;
  }
}

.collection-preview:hover {
  box-shadow: 0 7px 14px rgb(0 0 0 / 60%);
}

.sold-out-item {
  .image {
    opacity: 0.5;
  }

  .payment-tag {
    display: none;
  }
}
