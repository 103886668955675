.homepage {
  min-height: calc(100vh - 215px);
  padding-top: 100px;
  background-color: #f0f0f0;
}

.homepage-hero {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  &--text {
    max-width: 700px;

    h1 {
      margin-bottom: 20px;
      font-size: 64px;
      font-weight: 600;
      line-height: 75px;

      span {
        color: #dc2f5c;
        white-space: nowrap;
      }

      @media screen and (max-width: 578px) {
        font-size: 45px;
        line-height: 45px;
      }
    }

    h3 {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 10px;
    }
  }

  button {
    margin-top: 30px;
    border-radius: 10px;
    padding: 10px 55px;
    font-size: 32px;
    @media screen and (max-width: 578px) {
      font-size: 24px;
      padding: 8px 40px;
    }
  }
}
