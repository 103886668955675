.custom-button {
  min-width: 115px;
  min-height: 50px;
  letter-spacing: 0.5px;
  padding: 8px 25px;
  font-size: 18px;
  color: white;
  font-family: Montserrat, sans-serif !important;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all;
  border-radius: 5px;
  white-space: nowrap;
  cursor: pointer;
  @media screen and (max-width: 578px) {
    padding: 0 15px;
  }

  svg {
    margin-right: 10px;
  }

  &:hover {
    background-color: white;
    color: #202020;
    border: 1px solid #202020;
  }

  &.white-btn {
    background-color: white;
    color: #202020;
    border: 1px solid #202020;

    &:hover {
      background-color: #202020;
      color: white;
      border: 1px solid white;
    }
  }

  &.black-btn {
    background-color: #3a3a3c;
    color: #fff;
    border: 1px solid #3a3a3c;

    &:hover {
      background-color: #212122;
      color: white;
      border: 1px solid #212122;
    }
  }

  &.red-btn {
    background-color: #dc2f5c;
    color: #fff;
    border: 1px solid #dc2f5c;

    &:hover {
      background-color: #bd214b;
      color: white;
      border: 1px solid #bd214b;
    }
  }

  &.green-btn {
    background-color: #0e8104;
    color: #fff;
    border: 1px solid #0e8104;

    &:hover {
      background-color: #085302;
      color: white;
      border: 1px solid #085302;
    }
  }
}

.custom-disabled {
  cursor: not-allowed !important;
  opacity: 0.5;

  .custom-button {
    pointer-events: none;
    user-select: none !important;
  }
}
