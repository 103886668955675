.policy-page {
  padding: 100px 20px;
  min-height: calc(100vh - 85px);

  hr {
    border-color: black;
    margin: 20px 0;
  }

  h1 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  h3 {
    font-weight: 600;
  }

  p {
    margin-bottom: 10px;

    span {
      font-weight: 600;
      color: #dc2f5c;
    }
  }
}
