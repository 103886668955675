.checkout-item {
  width: 100%;
  display: flex;
  min-height: 100px;
  border-bottom: 1px solid darkgrey;
  padding: 15px 0;
  font-size: 20px;
  align-items: center;
  justify-content: space-between;

  .image-container {
    width: 250px;
    padding-right: 20px;
    position: relative;

    .pre-order-tag {
      position: absolute;
      left: 10px;
      top: 10px;
    }

    img {
      width: 50%;
      height: 50%;
    }
    @media screen and (max-width: 768px) {
      width: 100px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .quantity,
  .price,
  .subtotal {
    width: 100px;
  }

  .quantity,
  .price {
    @media screen and (max-width: 768px) {
      display: none !important;
    }
  }

  .name {
    width: 350px;
    padding-right: 20px;

    @media screen and (max-width: 768px) {
      width: 200px;
    }
  }

  .product-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .mobile-quantity {
      display: none;

      .arrow {
        cursor: pointer;
        font-size: 16px !important;
      }

      .value {
        margin: 0 10px;
      }

      .disable {
        pointer-events: none;
        user-select: none;
        color: #999;
      }

      @media screen and (max-width: 768px) {
        display: flex;
        align-items: center;
      }
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 10px;

      p {
        font-size: 16px;
      }
    }
  }

  .quantity {
    display: flex;
    align-items: center;

    .arrow {
      cursor: pointer;
      font-size: 16px !important;
    }

    .value {
      margin: 0 10px;
    }

    .disable {
      pointer-events: none;
      user-select: none;
      color: #999;
    }
  }

  .remove-button {
    cursor: pointer;
    width: 60px;
    text-align: center;
    font-size: 12px;
    background-color: #dc2f5c;
    color: #fff;
    border-radius: 3px;
    transition: 0.3s all;
    margin-top: 10px;
  }

  .remove-button:hover {
    background-color: #bd214b;
  }
}
