.errorpage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 215px);

  &--content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .error-icon {
      width: 300px;
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 50px;
    }

    .error-detail {
      max-width: 500px;

      h1 {
        font-size: 72px;
      }

      h3 {
        font-size: 35px;
      }
    }
  }

  .error-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    min-height: calc(100vh - 40px);

    .error-icon {
      width: 300px;
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h3 {
      font-size: 30px;
    }

    p {
      font-size: 20px;
      max-width: 700px;
      text-align: center;
    }
  }
}
