.errorpage {
  min-height: calc(100vh - 280px);
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 576px) {
    margin-top: 80px;
    margin-bottom: 50px;
  }

  &--content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .error-icon {
      width: 300px;
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 50px;
      @media screen and (max-width: 576px) {
        margin-right: 0;
      }
    }

    .error-detail {
      max-width: 500px;

      h1 {
        font-size: 72px;
        @media screen and (max-width: 576px) {
          font-size: 40px;
        }
      }

      h3 {
        font-size: 35px;
        @media screen and (max-width: 576px) {
          font-size: 25px;
        }
      }

      p {
        margin-bottom: 20px;
      }
    }
  }
}
