.shop-page {
  background-color: #f7f7f7;
  padding-top: 100px;
  min-height: calc(100vh - 215px);

  .text-banner {
    background: linear-gradient(to left, #1f5717, #114407);
    overflow: hidden;
    position: relative;
    height: 70px;

    h1 {
      font-size: 35px;
      line-height: 70px;
      font-weight: 600;
      color: rgb(246, 215, 155);
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translateX(100%);
      animation: scroll-left 20s linear infinite;
    }
    @keyframes scroll-left {
      0% {
        transform: translateX(100%);
      }

      100% {
        transform: translateX(-100%);
      }
    }
  }

  &--container {
    display: flex;
    flex-direction: column;

    h1 {
      margin-top: 50px;
      margin-bottom: 20px;
      font-size: 50px;
      font-weight: 600;
      padding: 0 20px;
      align-self: center;
      position: relative;
    }

    h1::after {
      content: '';
      position: absolute;
      height: 3px;
      width: 100%;
      background-color: #dc2f5c;
      bottom: -10px;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .egdepi-container {
    padding: 20px 0;

    .unavailable-text {
      font-size: 30px;
    }
  }
}
