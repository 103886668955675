.status-page {
  min-height: calc(100vh - 215px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 50px;

  &--content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .success-icon {
      width: 300px;
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 50px;
    }

    .status-page-detail {
      max-width: 800px;

      h1 {
        font-size: 40px;
        color: #1c6d12;
      }

      h2 {
        font-size: 30px;
        margin-bottom: 20px;

        span {
          color: #dc2f5c;
          font-weight: 500;
        }
      }

      h3 {
        font-size: 25px;
      }

      p {
        font-size: 20px;

        span {
          font-weight: 600;
        }
      }

      hr {
        border-color: #1c6d12;
        margin: 20px 0;
      }

      @media screen and (max-width: 578px) {
        h1 {
          font-size: 30px;
        }

        h2 {
          font-size: 20px;
        }

        h3 {
          font-size: 18px;
        }
      }
    }
  }

  .status-page-button-group {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    flex-wrap: wrap;

    button {
      margin-right: 5px;
      margin-bottom: 10px;
    }
  }

  .create-account-suggestion {
    font-family: Montserrat, sans-serif !important;

    li {
      margin-left: 15px;
    }
  }

  .success-button-group {
    margin-top: 30px;
    display: flex;

    button {
      margin-right: 20px;
    }
  }
}
