.pricing-plans-page {
  h2 {
    font-family: Montserrat, sans-serif;
  }

  &--heading {
    h2 {
      font-size: 48px;
      font-weight: 700;
      text-align: center;
    }

    p {
      font-size: 32px;
      font-weight: 500;
      color: #5b5b5b;
      text-align: center;
    }
  }

  .plan-name {
    margin: 0 !important;
    color: #dc2f5c;
    font-size: 20px;
    font-weight: 700;
    word-wrap: break-word;
    display: flex;
    align-items: center;
  }

  .plan-features {
    span {
      font-family: Montserrat, sans-serif;
    }
  }

  .new-plan-contact {
    margin-top: auto;
    height: 30px;
  }

  .plans-table {
    table {
      white-space: pre-wrap;
      word-wrap: break-word;
      border-bottom: 1px solid #00000015;
    }

    tbody {
      tr:nth-child(odd) {
        background: #efefef;
        border-top: 1px solid #00000015;
        border-bottom: 1px solid #00000015;
      }

      td,
      th {
        border: none;
      }
    }

    th {
      text-transform: unset;
      font-family: Montserrat, sans-serif;
      font-size: 18px;
      border: none;
    }

    td {
      padding: 5px;
      font-weight: 500;
      background: none !important;
    }

    .table-heading {
      display: flex;
      align-items: center;
    }

    .plan-item {
      position: relative;
      padding-top: 32px;
      min-width: 200px !important;
      max-width: 200px !important;
    }

    .desktop-plan-header {
      overflow: visible !important;

      .desktop-plan-price {
        font-size: 20px;
      }
    }

    .contact-us {
      width: 100%;
      margin-top: 20px;
      text-align: right;
      font-size: 1.2rem;
      font-family: Montserrat, sans-serif;
      color: #dc2f5c;
      font-weight: 600;

      span {
        color: #000;
        font-size: 1rem;
        font-weight: 300;
      }

      a {
        font-size: 1rem;
        text-decoration: underline;
        transition: 0.3s all;
        font-weight: 6 00;
      }

      a:hover {
        color: #bd214b;
      }
    }

    .icon-tooltip {
      margin-left: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .card-badge {
    &--ribbon {
      position: absolute;
      right: -80px;
      top: 24px;
      width: 240px;
      transform: rotate(45deg);
    }

    &--regular {
      position: absolute;
      left: 0;
      top: -30px;
      width: 100%;
      border-radius: 5px;
    }
  }

  .subscribe-btn {
    font-family: Montserrat, sans-serif;
    background-color: #dc2f5c;
    color: #fff;
    justify-self: center;
    font-size: 14px;
    height: 30px;
  }

  .subscribe-btn-disabled {
    font-family: Montserrat, sans-serif;
    background-color: #6f6f6f;
    color: #fff;
    justify-self: center;
    font-size: 14px;
    height: 30px;
  }

  .subscribe-btn-disabled:hover {
    background-color: #3f3f3f !important;
  }

  .subscribe-btn:hover {
    background-color: #bd214b;
  }

  .disabled-btn {
    pointer-events: none;
    user-select: none;
  }

  .plans-mobile-container {
    h2 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 10px;
      text-align: center;
    }

    p {
      font-size: 20px;
      margin-bottom: 50px;
      text-align: center;
    }
  }
}
