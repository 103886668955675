.shipping-page {
  min-height: calc(100vh - 215px);
  display: flex;
  justify-content: center;
  padding: 140px 50px 50px;
  @media screen and (max-width: 768px) {
    padding: 140px 20px 50px;
  }

  .text-banner {
    margin-bottom: 40px;
    position: relative;

    .back-btn {
      cursor: pointer;
      position: absolute;
      left: -80px;
      @media screen and (max-width: 768px) {
        left: -5px;
        position: relative;
      }
    }
  }

  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  h2 {
    font-size: 30px;
    font-weight: 600;
    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }

  h3 {
    font-size: 25px;
    font-weight: 600;
    @media screen and (max-width: 768px) {
      font-size: 23px;
    }
  }

  h4 {
    font-size: 20px;
    font-weight: 600;
  }

  .empty-cart-message {
    h3 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 20px;
    }
  }

  .contact-information {
    h3 {
      display: flex;
      margin-bottom: 20px;

      span {
        display: flex;
        align-items: center;
        margin-left: 10px;
        opacity: 0.4;
      }
    }

    h4 {
      display: flex;
    }

    h4 > span {
      display: flex;
      align-items: center;
      margin-left: 10px;
    }

    p {
      display: flex;
      align-items: center;
    }
  }

  .shipping-information {
    margin-top: 50px;

    h3 {
      display: flex;
      margin-bottom: 20px;
    }

    h3 > span {
      display: flex;
      align-items: center;
      margin-left: 10px;
      opacity: 0.4;
    }
  }

  .asterisk {
    color: #dc2f5c;
    font-weight: 600;
  }

  .custom-input {
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0;
    margin-bottom: 30px;
    max-width: 300px;
    font-family: Montserrat, sans-serif !important;
  }

  .custom-input::placeholder {
    font-family: Montserrat, sans-serif !important;
  }

  .custom-input:focus {
    box-shadow: none;
    border-color: #dc2f5c;
  }

  .custom-auto-complete-input {
    margin-bottom: 40px;
    font-family: Montserrat, sans-serif;

    .address {
      max-width: unset !important;
      box-shadow: none;
      padding: 0;

      #location {
        font-family: Montserrat, sans-serif !important;
      }
    }

    .addressFields {
      display: none !important;
    }

    .address-field {
      label {
        color: rgb(57 57 57) !important;
        font-size: 14px;
      }

      input {
        font-size: 16px;
      }
    }

    .addressInput input::placeholder {
      font-family: Montserrat, sans-serif !important;
    }

    .address-field > input {
      border: none;
      border-bottom: 1px solid #000;
      border-radius: 0;
      max-width: 300px;
      font-family: Montserrat, sans-serif !important;
    }

    .address-field > input:focus {
      box-shadow: none;
      border-color: #dc2f5c;
      background-color: transparent;
    }
  }

  .manual-address-info {
    margin-top: 20px;
    font-family: Montserrat, sans-serif !important;
  }

  .form-btn-group {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    button {
      margin: 10px;
      font-family: Montserrat, sans-serif !important;
    }
  }

  .chakra-select__icon-wrapper {
    top: 30%;
  }
}
