.checkout-process-page {
  min-height: calc(100vh - 215px);
  padding: 100px 50px !important;
  font-family: Montserrat, sans-serif;

  @media screen and (max-width: 576px) {
    padding: 10px !important;

    #RFS-StepperContainer {
      margin-top: 70px;

      #RFS-Label {
        font-size: 15px;
      }
    }
  }

  h2 {
    font-family: Montserrat, sans-serif;
  }

  h3 {
    font-family: Montserrat, sans-serif;
    font-size: 25px;
    color: #dc2f5c;
  }

  h5 {
    font-family: Montserrat, sans-serif;
    font-size: 20px;
  }

  .chakra-steps {
    padding-bottom: 30px;
    border-bottom: 1px solid #b4b2b257;
    max-width: 600px;
    margin: auto;
    margin-bottom: 70px;

    svg {
      fill: #dc2f5c;
    }
  }

  .steps-btn-group {
    border-top: 1px solid #b4b2b257;
    padding-top: 20px;

    .chakra-button {
      background: #38a169;
      border-radius: 0;
      color: #fff;
      width: 140px;
      height: 40px;
    }
  }

  .css-z2qhbs {
    width: 100%;
  }

  .create-account-stack {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .create-account-suggestion {
      font-size: 20px;
      margin-bottom: 30px;

      li {
        font-size: 16px;
        margin-left: 15px;
      }
    }
  }

  .completed-container {
    &--body {
      h1 {
        font-family: Montserrat, sans-serif;
        font-size: 45px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 20px;
        @media screen and (max-width: 576px) {
          font-size: 25px;
        }
      }

      h5 {
        margin-bottom: 30px;

        span {
          font-weight: 600;
        }

        @media screen and (max-width: 576px) {
          font-size: 20px;
        }
      }
    }
  }

  .checkout-process-page-error {
    h1 {
      font-size: 32px;
      font-weight: 600;
      margin-top: 30px;
    }

    h3 {
      margin-bottom: 50px;
    }
  }

  .success-button-group {
    margin-top: 30px;
    display: flex;

    button {
      margin: 10px;
    }
  }
}
