.price-box {
  display: flex;
  align-items: flex-end;

  h4 {
    font-size: 30px;
    line-height: 35px;
    color: #000;
    font-family: Montserrat, sans-serif;
    font-weight: 500;
    letter-spacing: -2px;
    @media screen and (max-width: 768px) {
      font-size: 20px;
      line-height: 20px;
    }
  }

  .regular {
    margin-top: 10px;
    font-size: 25px;
  }

  p {
    font-size: 13px;
    line-height: 19px;
    margin-left: 4px;
    color: #5b5b5b;
    font-weight: 600;
  }
}
