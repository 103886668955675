@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

body {
  color: #202020;
  padding: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
p,
input,
label,
span {
  font-family: Montserrat, sans-serif;
}

a {
  text-decoration: none;
  color: #202020;
}

* {
  box-sizing: border-box;
}

.custom-btn {
  width: 240px;
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 25px;
  font-size: 15px;
  background-color: #dc2f5c;
  color: white;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-weight: bolder;
  cursor: pointer;
  transition: 0.3s all;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.custom-btn:hover {
  background-color: #bd214b;
  color: white;
}

.chakra-button {
  font-family: Montserrat, sans-serif !important;
}

.chakra-modal__content,
.chakra-toast {
  font-family: Montserrat, sans-serif;
}

.chakra-modal__content .css-mn8nh9 {
  background-color: #dc2f5c !important;
  font-family: Montserrat, sans-serif;
}

.chakra-portal {
  font-family: Montserrat, sans-serif;
}

.static-page {
  min-height: calc(100vh - 140px);
  margin-top: 70px;

  &--banner {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 50px;
      font-weight: 600;
      color: #fff;
    }
  }

  h3 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  p {
    font-size: 20px;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
